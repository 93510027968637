import moment, { Moment } from 'moment/moment'
import * as time from '@/core/utils/time-helper'
import * as distance from '@/core/utils/distance-helper'

export interface UserStatisticPayload {
    id: number
    first_name: string
    last_name: string
    email: string
    phone: string
    gender: string
    city: string
    country_code_iso: string
    level: string
    preferred_locale: string
    provider: string
    activity_providers: string[]
    total_distance: number
    total_duration: string
    total_rest_time: string
    total_time: string
    workout_executions_count: number
    status: string
    registered_at: string
}

export class UserStatistic {

    id: number
    firstName: string
    lastName: string
    email: string
    phone: string
    gender: string
    city: string
    countryCodeIso: string
    level: string
    preferredLocale: string
    provider: string
    activityProviders: string[]
    totalDistance: number
    totalDuration: string
    totalRestTime: string
    totalTime: string
    workoutExecutionsCount: number
    status: string
    registeredAt: Moment

    constructor(data: UserStatisticPayload) {
        this.id = data.id
        this.firstName = data.first_name
        this.lastName = data.last_name || ''
        this.email = data.email
        this.phone = data.phone
        this.gender = data.gender
        this.city = data.city
        this.countryCodeIso = data.country_code_iso
        this.level = data.level
        this.preferredLocale = data.preferred_locale
        this.provider = data.provider
        this.activityProviders = data.activity_providers
        this.totalDistance = data.total_distance
        this.totalDuration = data.total_duration
        this.totalRestTime = data.total_rest_time
        this.totalTime = data.total_time
        this.workoutExecutionsCount = data.workout_executions_count
        this.status = data.status
        this.registeredAt = moment(data.registered_at)
    }

    fill(data: UserStatisticPayload): this {
        this.id = data.id
        this.firstName = data.first_name
        this.lastName = data.last_name || ''
        this.email = data.email
        this.phone = data.phone
        this.gender = data.gender
        this.city = data.city
        this.countryCodeIso = data.country_code_iso
        this.level = data.level
        this.preferredLocale = data.preferred_locale
        this.provider = data.provider
        this.activityProviders = data.activity_providers
        this.totalDistance = data.total_distance
        this.totalDuration = data.total_duration
        this.totalRestTime = data.total_rest_time
        this.totalTime = data.total_time
        this.workoutExecutionsCount = data.workout_executions_count
        this.status = data.status
        this.registeredAt = moment(data.registered_at)
        return this
    }

    public get _totalDistance(): string {
        return distance.format(this.totalDistance)
    }

    public get _totalDuration(): string {
        return time.format(this.totalDuration, true)
    }

    public get _totalRestTime(): string {
        return time.format(this.totalRestTime, true)
    }

    public get _totalTime(): string {
        return time.format(this.totalTime, true)
    }

    exists(): boolean {
        return !! this.id
    }

    unset(): void {
        this.fill(<any>{})
    }
}
