
<div class="page-customized">
    <v-progress-linear
        v-if="overlay"
        absolute
        indeterminate
    ></v-progress-linear>

    <app-bar
        :title="page.exists() ? t('page_title_update') : t('page_title_create')"
        back="/groups"
    />

    <v-layout class="mt-n8">
        <v-card
            width="100%"
            color="transparent"
            elevation="0"
            class="pb-8"
        >
            <v-card-text class="pb-0">
                <v-form class="mt-6">
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('page_label_name') }}</div>
                        <div class="horizontal-input">
                            <v-text-field
                                v-model="page.name"
                                type="text"
                                required
                                outlined
                                dense
                                :error-messages="form.errors.get('name')"
                            ></v-text-field>
                        </div>
                        <div class="horizontal-actions">
                            <edit-translations-modal
                                v-model="page.name"
                                :items="page.translations['name']"
                                @touch="(v) => page.translations['name'] = v"
                            ></edit-translations-modal>
                        </div>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('page_label_content') }}</div>
                        <div class="horizontal-input">
                            <tinymce
                                key="description"
                                v-model="page.content"
                            ></tinymce>
                        </div>
                        <div class="horizontal-actions">
                            <edit-translations-modal
                                v-model="page.content"
                                :items="page.translations['content']"
                                @touch="(v) => page.translations['content'] = v"
                                editor
                            ></edit-translations-modal>
                        </div>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('page_label_slug') }}</div>
                        <div class="horizontal-input">
                            <v-text-field
                                v-model="page.slug"
                                type="text"
                                required
                                outlined
                                dense
                                disabled
                                :error-messages="form.errors.get('slug')"
                            ></v-text-field>
                        </div>
                    </div>
                    <v-switch
                        class="ma-0"
                        v-model="page.published"
                        :label="t('page_label_published')"
                    ></v-switch>
                </v-form>
            </v-card-text>

            <v-card-actions class="px-4 pt-8">
                <v-btn
                    :to="{ name: 'pages' }"
                    class="mr-4"
                    color="primary"
                >{{ t('general_action_cancel') }}</v-btn>
                <v-btn
                    :loading="form.busy"
                    :disabled="form.busy"
                    color="orange"
                    @click.prgroup="submit"
                >{{ page.exists() ? t('general_action_save') : t('group_title_create') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-layout>
</div>
