
<div class="page-customized">
    <v-progress-linear
        v-if="overlay"
        absolute
        indeterminate
    ></v-progress-linear>

    <app-bar
        :title="notification.exists() ? t('notification_title_update') : t('notification_title_create')"
        back="/notifications"
    />

    <v-layout class="mt-n8">
        <v-card
            width="100%"
            color="transparent"
            elevation="0"
            class="pb-8"
        >
            <v-card-text class="pb-0">
                <v-form class="mt-6">
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('notification_label_title') }}</div>
                        <div class="horizontal-input">
                            <v-text-field
                                v-model="notification.title"
                                type="text"
                                required
                                outlined
                                dense
                                :error-messages="form.errors.get('title')"
                            ></v-text-field>
                        </div>
                        <div class="horizontal-actions">
                            <edit-translations-modal
                                v-model="notification.title"
                                :items="notification.translations['title']"
                                @touch="(v) => notification.translations['title'] = v"
                            ></edit-translations-modal>
                        </div>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('notification_label_message') }}</div>
                        <div class="horizontal-input">
                            <v-textarea
                                v-model="notification.message"
                                required
                                outlined
                                dense
                                :error-messages="form.errors.get('message')"
                            ></v-textarea>
                        </div>
                        <div class="horizontal-actions">
                            <edit-translations-modal
                                v-model="notification.message"
                                :items="notification.translations['message']"
                                @touch="(v) => notification.translations['message'] = v"
                                text
                            ></edit-translations-modal>
                        </div>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('notification_label_photo') }}</div>
                        <div class="horizontal-input">
                            <v-file-input
                                v-model="notification.photo"
                                required
                                outlined
                                prepend-icon=""
                                prepend-inner-icon="mdi-paperclip"
                                :append-outer-icon="notification.photoUrl ? 'mdi-eye-outline' : ''"
                                dense
                                :error-messages="form.errors.get('photo')"
                                @click:append-outer="viewPhoto()"
                                @click:clear="notification.photo = undefined; photoForm.errors.clear('photo')"
                            ></v-file-input>
                        </div>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('notification_label_send_at') }}</div>
                        <div class="horizontal-input">
                            <v-text-field
                                v-model="sendAt"
                                type="datetime-local"
                                required
                                outlined
                                dense
                                :error-messages="form.errors.get('send_at')"
                            ></v-text-field>
                        </div>
                    </div>
                    <v-switch
                        class="ma-0"
                        v-model="notification.cta"
                        :label="t('notification_label_cta')"
                    ></v-switch>
                    <template v-if="notification.cta">
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('notification_label_cta_text') }}</div>
                            <div class="horizontal-input">
                                <v-text-field
                                    v-model="notification.ctaText"
                                    type="text"
                                    required
                                    outlined
                                    dense
                                    :error-messages="form.errors.get('cta_text')"
                                ></v-text-field>
                            </div>
                            <div class="horizontal-actions">
                                <edit-translations-modal
                                    v-model="notification.ctaText"
                                    :items="notification.translations['cta_text']"
                                    @touch="(v) => notification.translations['cta_text'] = v"
                                ></edit-translations-modal>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('notification_label_cta_params') }}</div>
                            <div class="horizontal-input">
                                <v-textarea
                                    v-model="notification.ctaParamsStr"
                                    required
                                    outlined
                                    dense
                                    :error-messages="form.errors.get('cta_params')"
                                ></v-textarea>
                            </div>
                        </div>
                    </template>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('notification_label_role_id') }}</div>
                        <div class="horizontal-input">
                            <v-select
                                v-model="notification.roleId"
                                :items="roles"
                                required
                                outlined
                                append-icon="mdi-chevron-down"
                                dense
                                :error-messages="form.errors.get('role_id')"
                            ></v-select>
                        </div>
                    </div>
                    <template v-if="! notification.roleId">
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('notification_label_attendees') }}</div>
                            <div class="horizontal-input">
                                <user-search-modal
                                    v-model="notification"
                                >
                                    <template #default="{ on, attrs }">
                                        <v-btn
                                            class="exs mt-1"
                                            color="primary"
                                            small
                                            v-on="on"
                                            v-bind="attrs"
                                        >{{ t('general_action_add_new') }}</v-btn>
                                    </template>
                                </user-search-modal>
                            </div>
                        </div>
                        <v-card
                            v-if="notification.users.length"
                            class="app-card app-card-bordered mt-6"
                        >
                            <v-card-text>
                                <v-list>
                                    <v-list-item
                                        v-for="user in notification.users"
                                        :key="`user_${user.id}`"
                                    >
                                        {{ user.fullName }} | {{ user.id }}
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            @click="detach(user)"
                                            v-ripple="false"
                                            plain
                                            color="orange darken-4"
                                            style="font-size: 12px"
                                        >{{ t('general_action_remove') }}</v-btn>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>
                        <div class="pb-6"></div>
                    </template>
                    <v-switch
                        class="ma-0"
                        v-model="notification.force"
                        :label="t('notification_label_force')"
                    ></v-switch>
                </v-form>
            </v-card-text>
            <v-card-actions class="px-4">
                <v-btn
                    :to="{ name: 'notifications' }"
                    class="sm mr-4"
                    color="orange"
                >{{ t('general_action_cancel') }}</v-btn>
                <v-btn
                    :loading="form.busy"
                    :disabled="form.busy"
                    color="primary"
                    class="sm mr-4"
                    @click.prnotification="submit('send')"
                >{{ t('general_action_save') }}</v-btn>
                <v-btn
                    :loading="form.busy"
                    :disabled="form.busy"
                    class="sm"
                    color="primary"
                    outlined
                    @click.prnotification="submit('draft')"
                >{{ t('general_action_draft') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-layout>
</div>
