<template>
    <div class="page-customized">
        <v-progress-linear
            v-if="overlay"
            absolute
            indeterminate
        ></v-progress-linear>

        <app-bar
            :title="event.exists() ? t('event_title_update') : t('event_title_create')"
            back="/events"
        />

        <v-layout class="mt-n8">
            <v-card
                width="100%"
                color="transparent"
                elevation="0"
                class="pb-8"
            >
                <v-card-text class="pb-0">
                    <v-form class="mt-6">
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('event_label_name') }}</div>
                            <div class="horizontal-input">
                                <v-text-field
                                    v-model="event.name"
                                    type="text"
                                    required
                                    outlined
                                    dense
                                    :error-messages="form.errors.get('name')"
                                ></v-text-field>
                            </div>
                            <div class="horizontal-actions">
                                <edit-translations-modal
                                    v-model="event.name"
                                    :items="event.translations['name']"
                                    @touch="(v) => event.translations['name'] = v"
                                ></edit-translations-modal>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('event_label_description') }}</div>
                            <div class="horizontal-input">
                                <tinymce
                                    key="description"
                                    v-model="event.description"
                                ></tinymce>
                            </div>
                            <div class="horizontal-actions">
                                <edit-translations-modal
                                    v-model="event.description"
                                    :items="event.translations['description']"
                                    @touch="(v) => event.translations['description'] = v"
                                    editor
                                ></edit-translations-modal>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('general_label_created_by') }}</div>
                            <div class="horizontal-input">
                                <v-select
                                    v-model="event.creator"
                                    :items="communityCreators()"
                                    required
                                    outlined
                                    append-icon="mdi-chevron-down"
                                    dense
                                    :error-messages="form.errors.get('creator')"
                                ></v-select>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('event_label_photo') }}</div>
                            <div class="horizontal-input">
                                <v-file-input
                                    v-model="event.photo"
                                    required
                                    outlined
                                    prepend-icon=""
                                    prepend-inner-icon="mdi-paperclip"
                                    :append-outer-icon="event.photoUrl ? 'mdi-eye-outline' : ''"
                                    dense
                                    :error-messages="form.errors.get('photo')"
                                    @click:append-outer="viewPhoto()"
                                    @click:clear="event.photo = undefined; photoForm.errors.clear('photo')"
                                ></v-file-input>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('event_label_location') }}</div>
                            <div class="horizontal-input">
                                <google-autocomplete
                                    v-model="event.location"
                                    id="google-address"
                                    @change="updateAutocompleteText"
                                    @placechanged="getAddressData"
                                    dense
                                    outlined
                                    fulled
                                    types="(cities)"
                                    placeholder="Type city"
                                    :error-messages="form.errors.get('location_coordinates')"
                                    :disabled="eventTypeVirtual"
                                />
                            </div>
                        </div>
                        <v-switch
                            class="ma-0"
                            v-model="eventTypeVirtual"
                            :label="t('event_label_virtual')"
                        ></v-switch>
                        <v-switch
                            class="ma-0"
                            v-model="event.private"
                            :label="t('event_label_private')"
                        ></v-switch>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('event_label_repeat_type') }}</div>
                            <div class="horizontal-input">
                                <v-select
                                    v-model="event.repeatType"
                                    :items="repeatTypes()"
                                    required
                                    outlined
                                    append-icon="mdi-chevron-down"
                                    dense
                                    :error-messages="form.errors.get('repeat_type')"
                                ></v-select>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('event_label_start_at') }}</div>
                            <div class="horizontal-input">
                                <v-text-field
                                    v-model="startAt"
                                    type="datetime-local"
                                    required
                                    outlined
                                    dense
                                    :error-messages="form.errors.get('start_at')"
                                ></v-text-field>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('event_label_end_at') }}</div>
                            <div class="horizontal-input">
                                <v-text-field
                                    v-model="endAt"
                                    type="datetime-local"
                                    required
                                    outlined
                                    dense
                                    :error-messages="form.errors.get('end_at')"
                                ></v-text-field>
                            </div>
                        </div>
                        <div
                            v-if="event.repeatType !== 'one_time'"
                            class="form-group-horizontal"
                        >
                            <div class="horizontal-label">{{ t('event_label_repeat_count') }}</div>
                            <div class="horizontal-input">
                                <v-text-field
                                    v-model="event.repeatCount"
                                    v-maska="'######'"
                                    type="number"
                                    step="1"
                                    min="2"
                                    required
                                    outlined
                                    dense
                                ></v-text-field>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('event_label_goal') }}</div>
                            <div class="horizontal-input">
                                <v-btn-toggle
                                    v-model="event.targetType"
                                    active-class="primary"
                                    class="my-1"
                                    dense
                                    mandatory
                                >
                                    <v-btn
                                        value="none"
                                        class="exs mr-3"
                                        small
                                    >{{ targetTypeTitle('none') }}</v-btn>
                                    <v-btn
                                        value="distance"
                                        class="exs mr-3"
                                        small
                                    >{{ targetTypeTitle('distance') }}</v-btn>
                                    <v-btn
                                        value="duration"
                                        class="exs"
                                        small
                                    >{{ targetTypeTitle('duration') }}</v-btn>
                                </v-btn-toggle>
                                <v-input
                                    type="hidden"
                                    :error-messages="form.errors.get('target_type')"
                                ></v-input>
                            </div>
                        </div>
                        <template v-if="event.targetType !== 'none'">
                            <div class="form-group-horizontal" v-if="event.targetType === 'distance'">
                                <div class="horizontal-label">{{ t('event_label_distance') }}</div>
                                <div class="horizontal-input">
                                    <v-text-field
                                        v-model="event.distance"
                                        v-maska="'######'"
                                        type="number"
                                        step="1"
                                        required
                                        outlined
                                        dense
                                        :error-messages="form.errors.get('distance')"
                                    ></v-text-field>
                                </div>
                            </div>
                            <div class="form-group-horizontal" v-if="event.targetType === 'duration'">
                                <div class="horizontal-label">{{ t('event_label_duration') }}</div>
                                <div class="horizontal-input">
                                    <v-text-field
                                        v-model="event.duration"
                                        required
                                        outlined
                                        dense
                                        v-maska="'##:##:##.###'"
                                        :error-messages="form.errors.get('duration')"
                                    ></v-text-field>
                                </div>
                            </div>
                            <div class="form-group-horizontal">
                                <div class="horizontal-label">{{ t('event_label_stroke') }}</div>
                                <div class="horizontal-input">
                                    <v-select
                                        v-model="event.strokeId"
                                        :items="strokes"
                                        required
                                        outlined
                                        append-icon="mdi-chevron-down"
                                        dense
                                        :error-messages="form.errors.get('stroke_id')"
                                    ></v-select>
                                </div>
                            </div>
                        </template>
                        <div class="form-group-horizontal" v-if="event.photos && event.photos.length">
                            <v-sheet
                                v-for="(photo, i) in event.photos"
                                :key="photo"
                                color="white"
                                elevation="0"
                                height="200"
                                width="200"
                                class="float-left mr-8 mb-8 bordered position-relative"
                            >
                                <v-img
                                    :lazy-src="photo"
                                    :src="photo"
                                    max-height="150"
                                    max-width="150"
                                    class="mx-auto mt-6"
                                ></v-img>
                                <confirm-modal
                                    :title="t('file_manager_label_confirm_delete')"
                                    @agree="event.delPhoto(i)"
                                >
                                    <template #default="{ on, attrs }">
                                        <v-btn
                                            plain
                                            icon
                                            absolute
                                            top
                                            right
                                            class="mt-n5 mr-n5"
                                            v-on="on"
                                            v-bind="attrs"
                                        ><v-icon color="customized-red">mdi-close</v-icon></v-btn>
                                    </template>
                                </confirm-modal>
                                <v-btn
                                    v-if="i !== 0"
                                    @click="event.updatePhotoNumber(i, (i - 1))"
                                    plain
                                    icon
                                    absolute
                                    bottom
                                    left
                                    class="mb-n5 ml-n5"
                                ><v-icon color="customized-primary">mdi-arrow-left</v-icon></v-btn>
                                <v-btn
                                    v-if="i !== event.photos.length - 1"
                                    @click="event.updatePhotoNumber(i, (i + 1))"
                                    plain
                                    icon
                                    absolute
                                    bottom
                                    right
                                    class="mb-n5 mr-n5"
                                ><v-icon color="customized-primary">mdi-arrow-right</v-icon></v-btn>
                            </v-sheet>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('event_label_photos') }}</div>
                            <div class="horizontal-input">
                                <v-file-input
                                    v-model="event.photoUps"
                                    required
                                    outlined
                                    prepend-icon=""
                                    prepend-inner-icon="mdi-paperclip"
                                    dense
                                    multiple
                                    :placeholder="t('file_manager_label_browse_files')"
                                    :error-messages="photoForm.errors.get('photos.0') || photoForm.errors.get('photos')"
                                    @click:clear="event.photoUps = undefined; photoForm.errors.clear('photos.0'); photoForm.errors.clear('photos')"
                                ></v-file-input>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('event_label_registration_url') }}</div>
                            <div class="horizontal-input">
                                <v-text-field
                                    v-model="event.registrationUrl"
                                    type="text"
                                    required
                                    outlined
                                    dense
                                    :error-messages="form.errors.get('registration_url')"
                                ></v-text-field>
                            </div>
                        </div>
                        <v-switch
                            class="ma-0"
                            v-model="event.hideUsers"
                            :label="t('event_label_hide_users')"
                        ></v-switch>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('event_label_attendees') }}</div>
                            <div class="horizontal-input">
                                <user-search-modal
                                    v-model="event"
                                >
                                    <template #default="{ on, attrs }">
                                        <v-btn
                                            class="exs mt-1"
                                            color="primary"
                                            small
                                            v-on="on"
                                            v-bind="attrs"
                                        >{{ t('general_action_add_new') }}</v-btn>
                                    </template>
                                </user-search-modal>
                            </div>
                        </div>
                        <v-card
                            v-if="event.participants.length"
                            class="app-card app-card-bordered mt-6"
                        >
                            <v-card-text>
                                <v-list>
                                    <v-list-item
                                        v-for="participant in event.participants"
                                        :key="`participant_${participant.id}`"
                                    >
                                        {{ participant.fullName }} | {{ participant.id }}
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            @click="detach(participant)"
                                            v-ripple="false"
                                            plain
                                            color="orange darken-4"
                                            style="font-size: 12px"
                                        >{{ t('general_action_remove') }}</v-btn>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-form>
                </v-card-text>

                <v-card-actions class="px-4 pt-8">
                    <v-btn
                        :to="{ name: 'events' }"
                        class="mr-4"
                        color="primary"
                    >{{ t('general_action_cancel') }}</v-btn>
                    <v-btn
                        :loading="form.busy"
                        :disabled="form.busy"
                        color="orange"
                        @click.prevent="submit"
                    >{{ event.exists() ? t('general_action_save') : t('event_title_create') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-layout>
    </div>
</template>

<script lang="ts">
    import { computed, defineComponent, onMounted, reactive, toRefs, watch } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useRouter } from '@/composition-api'
    import { useForm } from '@/core/form'
    import { useEnums } from '@/core/defaults'
    import { User } from '@/core/models/user'
    import { Event } from '@/core/models/event'
    import { Stroke } from '@/core/models/stroke'
    import moment from 'moment/moment'
    import EditTranslationsModal from '@/components/EditTranslationsModal.vue'
    import GoogleAutocomplete from '@/components/GoogleAutocomplete.vue'
    import UserSearchModal from '@/components/events/UserSearchModal.vue'
    import ConfirmModal from '@/components/ConfirmModal.vue'
    import Tinymce from '@/components/Tinymce.vue'

    export default defineComponent({
        components: {
            EditTranslationsModal,
            GoogleAutocomplete,
            UserSearchModal,
            ConfirmModal,
            Tinymce,
        },

        /**
         * Component constructor.
         *
         * @param props
         */
        setup(props) {
            const store = useStore()
            const router = useRouter()
            const id = router.currentRoute.params['id'] || undefined
            const captured = router.currentRoute.query['id'] || undefined

            const enums = useEnums()
            const i18n = useI18n()

            const eventModel = computed(() => <Event>store.getters['event/get'])

            const state = reactive({
                overlay: true,
                eventTypeVirtual: true,
                userSearchModal: false,
                startAt: <string | undefined>undefined,
                endAt: <string | undefined>undefined,
            })


            const strokes = computed(() => [ { text: '-', value: null } ].concat(
                store.getters['strokeCollection/get'].data.map((item: Stroke) => {
                    return { text: item.name, value: item.id }
                })
            ))

            const capture = () => {
                store.dispatch(
                    'event/getOrNew',
                    {
                        id: (id || captured),
                        payload: { append: 'translations', relations: 'schedules,participants' }
                    }).then(() => {
                        state.overlay = false
                        state.eventTypeVirtual = eventModel.value.eventType === 'virtual'
                        state.startAt = eventModel.value.startAt?.format('YYYY-MM-DDTHH:mm')
                        state.endAt = eventModel.value.endAt?.format('YYYY-MM-DDTHH:mm')
                    })
            }

            const form = reactive(useForm())
            const photoForm = reactive(useForm())

            const submit = () => {
                if (captured) {
                    eventModel.value.id = <any>undefined
                }
                form.usingSubmit(store.dispatch('event/updateOrCreate', {
                    payload: eventModel.value.payload()
                }))
                    .then(() => {
                        const id = <any>eventModel.value.id
                        const next = () => router.push({ name: 'events.view', params: <any>{ id: id } })
                        store.dispatch('notify', { color: 'success', message: i18n.t('general_notice_data_updated') })
                        if (eventModel.value.photo || eventModel.value.photos) {
                            const data = new FormData()
                            if (eventModel.value.photo) {
                                data.append('photo', eventModel.value.photo)
                            }
                            if (eventModel.value.photoUps) {
                                eventModel.value.photoUps.map(i => data.append('photos[]', i))
                            }
                            photoForm.usingSubmit(
                                store.dispatch('event/photo', { id: id, payload: data }).then(() => { next() })
                            ).catch(() => {
                                store.dispatch('notify', { color: 'error', message: i18n.t('general_notice_upload_failed') })
                            })
                        } else {
                            next()
                        }
                    })
            }

            const viewPhoto = () => {
                window.open(eventModel.value.photoUrl)
            }

            const detach = (user: User) => {
                eventModel.value.participants = eventModel.value.participants.filter(u => u.id !== user.id)
            }

            const getAddressData = (addressData: any, placeResultData: any, id: string) => {
                eventModel.value.location = addressData.country + ', ' + addressData.locality
                eventModel.value.locationCoordinates = {
                    latitude: placeResultData.geometry.location.lat(),
                    longitude: placeResultData.geometry.location.lng()
                }
            }

            const updateAutocompleteText = (value: any) => {
                eventModel.value.location = value
            }

            watch(() => state.eventTypeVirtual, (newValue) => {
                eventModel.value.eventType = state.eventTypeVirtual ? 'virtual' : 'physical'
                if (state.eventTypeVirtual) {
                    eventModel.value.location = null
                    eventModel.value.locationCoordinates = null
                }
            })

            watch(() => [state.startAt], (newValue: any) => {
                eventModel.value.startAt = moment(newValue, moment.HTML5_FMT.DATETIME_LOCAL)
            })

            watch(() => [state.endAt], (newValue: any) => {
                eventModel.value.endAt = moment(newValue, moment.HTML5_FMT.DATETIME_LOCAL)
            })

            watch(() => [eventModel.value.repeatType], (newValue: any) => {
                if (eventModel.value.repeatType === 'one_time') {
                    eventModel.value.repeatCount = 1
                } else if (eventModel.value.repeatCount === 1) {
                    eventModel.value.repeatCount = 10
                }
            })

            onMounted(() => {
                store.dispatch('strokeCollection/get', { sort: 'sort_order', type: 'primary' })
                capture()
            })

            return {
                form,
                photoForm,
                capture,
                viewPhoto,
                detach,
                submit,
                event: eventModel.value,
                strokes,
                getAddressData,
                updateAutocompleteText,
                moment,
                ...enums,
                ...useI18n(),
                ...toRefs(state)
            }
        }
    })
</script>
