<template>
    <div class="page-customized">
        <v-progress-linear
            v-if="overlay"
            absolute
            indeterminate
        ></v-progress-linear>

        <app-bar
            :title="page.exists() ? t('page_title_update') : t('page_title_create')"
            back="/groups"
        />

        <v-layout class="mt-n8">
            <v-card
                width="100%"
                color="transparent"
                elevation="0"
                class="pb-8"
            >
                <v-card-text class="pb-0">
                    <v-form class="mt-6">
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('page_label_name') }}</div>
                            <div class="horizontal-input">
                                <v-text-field
                                    v-model="page.name"
                                    type="text"
                                    required
                                    outlined
                                    dense
                                    :error-messages="form.errors.get('name')"
                                ></v-text-field>
                            </div>
                            <div class="horizontal-actions">
                                <edit-translations-modal
                                    v-model="page.name"
                                    :items="page.translations['name']"
                                    @touch="(v) => page.translations['name'] = v"
                                ></edit-translations-modal>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('page_label_content') }}</div>
                            <div class="horizontal-input">
                                <tinymce
                                    key="description"
                                    v-model="page.content"
                                ></tinymce>
                            </div>
                            <div class="horizontal-actions">
                                <edit-translations-modal
                                    v-model="page.content"
                                    :items="page.translations['content']"
                                    @touch="(v) => page.translations['content'] = v"
                                    editor
                                ></edit-translations-modal>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('page_label_slug') }}</div>
                            <div class="horizontal-input">
                                <v-text-field
                                    v-model="page.slug"
                                    type="text"
                                    required
                                    outlined
                                    dense
                                    disabled
                                    :error-messages="form.errors.get('slug')"
                                ></v-text-field>
                            </div>
                        </div>
                        <v-switch
                            class="ma-0"
                            v-model="page.published"
                            :label="t('page_label_published')"
                        ></v-switch>
                    </v-form>
                </v-card-text>

                <v-card-actions class="px-4 pt-8">
                    <v-btn
                        :to="{ name: 'pages' }"
                        class="mr-4"
                        color="primary"
                    >{{ t('general_action_cancel') }}</v-btn>
                    <v-btn
                        :loading="form.busy"
                        :disabled="form.busy"
                        color="orange"
                        @click.prgroup="submit"
                    >{{ page.exists() ? t('general_action_save') : t('group_title_create') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-layout>
    </div>
</template>

<script lang="ts">
    import { computed, defineComponent, onMounted, reactive, toRefs } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useRouter } from '@/composition-api'
    import { useForm } from '@/core/form'
    import { useEnums, useLocales } from '@/core/defaults'
    import { Page } from '@/core/models/page'
    import moment from 'moment/moment'
    import EditTranslationsModal from '@/components/EditTranslationsModal.vue'
    import Tinymce from '@/components/Tinymce.vue'

    export default defineComponent({
        components: {
            EditTranslationsModal,
            Tinymce
        },

        /**
         * Component constructor.
         *
         * @param props
         */
        setup(props) {
            const store = useStore()
            const router = useRouter()
            const id = router.currentRoute.params['id']
            const i18n = useI18n()

            const state = reactive({
                overlay: true,
                currentTab: null,
            })

            const capture = () => {
                store.dispatch('page/get', { id: id, payload: { append: 'translations' } }).then(() => {
                    state.overlay = false
                })
            }

            const pageModel = computed(() => <Page>store.getters['page/get'])
            
            const form = reactive(useForm())

            const submit = () => {
                form.usingSubmit(store.dispatch('page/update', { id: id, payload: pageModel.value.payload() }))
                    .then(() => {
                        store.dispatch('notify', { color: 'success', message: i18n.t('general_notice_data_updated') })
                        router.push({ name: 'pages' })
                    })
            }

            const back = () => {
                router.push({ name: 'pages', params: <any>{ id: id } })
            }

            onMounted(() => {
                capture()
            })

            return {
                form,
                capture,
                back,
                submit,
                page: pageModel.value,
                moment,
                ...useI18n(),
                ...useEnums(),
                ...useLocales(),
                ...toRefs(state)
            }
        }
    })
</script>
