
<v-card
    class="fill-width pb-8"
    color="transparent"
    rounded="0"
    elevation="0"
    :disabled="busy"
    loader-height="2"
>
    <slot name="filters"></slot>
    <slot name="alerts"></slot>

    <v-card-text class="pa-4">
        <v-simple-table class="transparent">
            <template v-slot:default>
                <slot name="data"></slot>
            </template>
        </v-simple-table>
    </v-card-text>

    <v-card-actions v-if="meta && meta.total > 0">
        <v-pagination
            v-model="queries.page"
            :length="pageCount"
            total-visible="7"
        ></v-pagination>
        <span class="pagination-meta">{{ t('general_pagination_meta', { from: meta.from, to: meta.to, total: meta.total }) }}</span>
    </v-card-actions>
    <slot name="export"></slot>
</v-card>
